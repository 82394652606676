var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"hjj_demo4"}},[_c('b-row',[_c('b-col',{staticClass:"text-center mb-1",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-title",staticStyle:{"letter-spacing":"0.5rem","font-size":"2rem"},style:('text-shadow:0 0 10px ' +
          (_vm.$store.state.appConfig.layout.skin === 'dark'
            ? '#283046'
            : '#ffffff'))},[_vm._v(" 湾厦中心入驻企业名录 ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticStyle:{"height":"calc(50vh - 3rem)"},style:('background-color:rgba(' +
          (_vm.$store.state.appConfig.layout.skin === 'dark'
            ? '41,48,68'
            : '255,255,255') +
          ',1)')},[_c('div',{staticClass:"py-0",staticStyle:{"height":"calc(100% - 5rem)"}},_vm._l((_vm.company_list),function(floorItem,floorIndex){return _c('b-row',{key:floorIndex,staticClass:"py-50",class:floorIndex !== 0 ? 'border-top' : 'pt-0'},[(!floorItem.list)?_c('b-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('strong',{staticStyle:{"font-size":"1.1rem","letter-spacing":"1px"}},[_vm._v(" "+_vm._s(floorItem.floor)+" ")])]),_c('b-col',{attrs:{"cols":"9"}},[_c('strong',{staticStyle:{"font-size":"1.1rem","letter-spacing":"2px"}},[_vm._v(_vm._s(floorItem.company || ""))])])],1)],1):_vm._e(),(floorItem.list)?_c('b-col',{attrs:{"cols":"12"}},_vm._l((floorItem.list),function(companyItem,companyIndex){return _c('b-row',{key:companyIndex},[_c('b-col',{attrs:{"cols":"3"}},[_c('strong',{staticStyle:{"font-size":"1.1rem","letter-spacing":"1px"}},[_vm._v(_vm._s(companyItem.room))])]),_c('b-col',{attrs:{"cols":"9"}},[_c('strong',{staticStyle:{"font-size":"1.1rem","letter-spacing":"2px"}},[_vm._v(_vm._s(companyItem.company))])])],1)}),1):_vm._e()],1)}),1)])],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center mb-1",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-title",staticStyle:{"letter-spacing":"0.5rem","font-size":"2rem"},style:('text-shadow:0 0 10px ' +
          (_vm.$store.state.appConfig.layout.skin === 'dark'
            ? '#283046'
            : '#ffffff'))},[_vm._v(" 湾厦中心室内外环境质量监测 ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('real-time-air-quality',{staticStyle:{"height":"calc(50vh - 15rem)"},attrs:{"vertical":true,"background-opacity":1}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }